import { ApplicationState } from '../Utils/Interfaces';
import Types from './ActionTypes';

const INITIAL_STATE: ApplicationState = {
  loaded: false
};

const reducer = (state = INITIAL_STATE, action: any): ApplicationState => {
  switch (action.type) {
    case Types.SET_LOADED: {
      return { ...state, loaded: action.payload };
    }
    default:
      return state;
  }
};

export default reducer;
