import './TopNav.scss';

import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import { Icon } from '@material-ui/core';

import logo from '../../../Assets/Graphics/logo.png';
import logoWindless from '../../../Assets/Graphics/logoWindless.png';
import wind from '../../../Assets/Graphics/wind.png';
import {
    Address, Email, EmailDisplay, MapURL, Phone, PhoneDisplay
} from '../../../Utils/Constants';
import { LINK_TYPES } from '../../../Utils/Interfaces';
import LinksContainer from './Links';
import MenuIcon from './MenuIcon';

class TopNav extends React.Component<any> {
  state: {
    menuClosed: boolean;
    height: number;
  };

  constructor(props: any) {
    super(props);
    this.state = {
      menuClosed: true,
      height: 200,
    };
  }

  componentDidMount() {
    const top = document.getElementById("top");
    let height = this.state.height;

    if (top) height = top.clientHeight;
    this.setState({ height: height });

    window.addEventListener("resize", () => {
      const top = document.getElementById("top");
      let height = this.state.height;

      if (top) height = top.clientHeight;
      this.setState({ menuClosed: true, height: height });
    });

    window.setTimeout(() => {
      const top = document.getElementById("top");
      let height = this.state.height;

      if (top) height = top.clientHeight;
      this.setState({ height: height });
    }, 100);
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.location.pathname !== prevProps.location.pathname && window)
      this.setState({ menuClosed: true });
  }

  render() {
    return (
      <div id="top-container">
        <div id="top">
          <div className="top-logoAndInfo">
            <div className="top-mapInfo animated fadeInLeft delay-2s">
              <a
                href={MapURL}
                target="_blank"
                rel="noopener noreferrer"
                className="top-mapLink"
              >
                <Icon className="top-icon" id="top-mapIcon">
                  place
                </Icon>
                <div className="top-mapText top-mapTextMobile">{`Open\nMap`}</div>
              </a>

              <div className="top-mapText">{Address}</div>
            </div>
            {this.logoAnimation()}
            <div className="top-contactInfo animated fadeInRight delay-2s">
              <div className="top-contactMethod top-contactPhone">
                <span className="top-contactText">{PhoneDisplay}</span>
                <a
                  className="top-contactLink top-phoneLink"
                  href={`tel:${Phone}`}
                >
                  <Icon className="top-contactIcon">phone</Icon>
                </a>
              </div>
              <div className="top-contactMethod top-contactEmail">
                <span className="top-contactTextWidescreen">
                  {EmailDisplay}
                </span>
                <span className="top-contactText">{`${EmailDisplay}`}</span>
                <a
                  className="top-contactLink top-emailLink"
                  href={`mailto:${Email}?subject=Roofing Project Question`}
                >
                  <Icon className="top-contactIcon">email</Icon>
                </a>
              </div>
            </div>
          </div>
          <div
            className={`top-menu ${
              this.state.menuClosed ? "top-menuClosed" : "top-menuOpen"
            }`}
            onClick={() =>
              this.setState({
                menuClosed: !this.state.menuClosed,
              })
            }
          >
            <MenuIcon closed={this.state.menuClosed} />
          </div>
          <div
            className="top-linksContainer"
            onClick={() => this.setState({ menuClosed: true })}
          >
            <div className="top-linksAnimation animated fadeInUp fast delay-1s">
              <LinksContainer
                type={LINK_TYPES.TOP}
                closed={this.state.menuClosed}
              />
            </div>
          </div>
        </div>
        <div
          className="top-spacer"
          style={{ height: `${this.state.height}px` }}
        />
      </div>
    );
  }

  logoAnimation() {
    return (
      <NavLink
        exact={true}
        to="/"
        className="top-logoLink animated fadeInDown fast"
      >
        <img
          src={wind}
          className="top-wind"
          alt="Economy Roofing And Siding Roof Construction Projects Home Residential Commercial"
        />
        <img
          src={wind}
          className="top-wind"
          alt="Economy Roofing And Siding Roof Construction Projects Home Residential Commercial"
        />
        <img
          src={wind}
          className="top-wind"
          alt="Economy Roofing And Siding Roof Construction Projects Home Residential Commercial"
        />
        <img
          src={wind}
          className="top-wind"
          alt="Economy Roofing And Siding Roof Construction Projects Home Residential Commercial"
        />
        <img
          src={wind}
          className="top-wind"
          alt="Economy Roofing And Siding Roof Construction Projects Home Residential Commercial"
        />
        <img
          src={logo}
          className="top-logo top-logoOverlay"
          alt="Economy Roofing And Siding Roof Construction Projects Home Residential Commercial"
        />
        <img
          src={logoWindless}
          className="top-logo"
          alt="Economy Roofing And Siding Roof Construction Projects Home Residential Commercial"
        />
      </NavLink>
    );
  }
}

export default withRouter(TopNav);
