import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import './index.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { render } from 'react-snapshot';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';

import App from './App/App';
import reducer from './Redux/Reducer';
import * as serviceWorker from './serviceWorker';

const middleware = applyMiddleware(thunk);
const store = createStore(reducer, middleware);

render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
