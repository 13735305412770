export interface HomeContainerProps {}

export interface AboutContainerProps {}

export interface GalleryContainerProps {}

export interface ContactContainerProps {}

export interface ServicesContainerProps {}

export interface ApplicationState {
  loaded: boolean;
}

export enum LINK_TYPES {
  STICKY = "links-sticky",
  TOP = "links-top",
  FOOTER = "links-footer",
}

export interface LinkProps {
  closed: boolean;
  type: LINK_TYPES;
}

export interface Link {
  display: string;
  url: string;
  sublinks?: Link[];
}

export interface MenuIconProps {
  closed: boolean;
}

export interface Social {
  link: string;
  icon: string;
  network: string;
}
