import './Map.scss';

import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

class Map extends React.Component {
  render() {
    return (
      <ScrollAnimation
        offset={0}
        animateOnce={true}
        animateIn="fadeIn"
        className="map"
      >
        <iframe
          className="map-frame"
          title="Economy Roofing Service Map"
          src="https://www.google.com/maps/d/u/0/embed?mid=1PDFK3Co-KHlqw7rKFnYutkFTbdswyPXD"
        ></iframe>
      </ScrollAnimation>
    );
  }
}

export default Map;
