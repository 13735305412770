import './ContactPane.scss';

import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import { Icon } from '@material-ui/core';

import {
    Email, EmailDisplay, Fax, FaxDisplay, OfficePhone, OfficePhoneDisplay, Phone, PhoneDisplay
} from '../../../Utils/Constants';

class ContactPane extends React.Component {
  state: {
    loaded: boolean;
  };

  constructor(props: any) {
    super(props);
    this.state = {
      loaded: false
    };
  }

  render() {
    const methodClasslist = `pane-contactMethod ${
      this.state.loaded ? "animated zoomIn fast" : "pane-contactMethodHidden"
    }`;

    return (
      <ScrollAnimation
        offset={0}
        animateOnce={true}
        className="pane-contactMethods"
        animateIn="fadeIn"
        duration={0}
        afterAnimatedIn={(visible) => {
          this.setState({ loaded: true });
          return undefined;
        }}
      >
        <div className={methodClasslist}>
          <a className="pane-contactLink" href={`tel:${OfficePhone}`}>
            <Icon className="pane-contactIcon pane-contactPhone">phone</Icon>
            {`Talk To\nAn Expert`}
          </a>
          <div className="pane-contactInfo">{OfficePhoneDisplay}</div>
        </div>
        <div className={methodClasslist}>
          <a className="pane-contactLink" href={`tel:${Phone}`}>
            <Icon className="pane-contactIcon pane-contactPhone">phone</Icon>
            {`Call The\nCorporate Office`}
          </a>
          <div className="pane-contactInfo">{PhoneDisplay}</div>
        </div>
        <div className={methodClasslist}>
          <a
            className="pane-contactLink"
            href={`mailto:${Email}?subject=Roofing Project Question`}
          >
            <Icon className="pane-contactIcon pane-contactEmail">email</Icon>
            {`Email US`}
          </a>
          <div className="pane-contactInfo">{EmailDisplay}</div>
        </div>
        <div className={methodClasslist}>
          <a className="pane-contactLink" href={`tel:${Fax}`}>
            <Icon className="pane-contactIcon pane-contactFax">print</Icon>
            {`Send A\nFax`}
          </a>
          <div className="pane-contactInfo">{FaxDisplay}</div>
        </div>
      </ScrollAnimation>
    );
  }
}

export default ContactPane;
