import "./App.scss";

import React from "react";
import { Route, Switch } from "react-router";

import ScrollToTop from "../Utils/ScrollToTop";
import FooterContainer from "./Common/Footer/Footer";
import NavContainer from "./Common/Nav/NavContainer";
import AboutContainer from "./Pages/About/AboutContainer";
import ContactContainer from "./Pages/Contact/ContactContainer";
import GalleryContainer from "./Pages/Gallery/GalleryContainer";
import HomeContainer from "./Pages/Home/HomeContainer";
import ServicesContainer from "./Pages/Services/ServicesContainer";

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <NavContainer />
        <ScrollToTop>
          <Switch>
            <Route exact path="/Home" component={HomeContainer} />
            <Route exact path="/AboutUs" component={AboutContainer} />
            <Route exact path="/Contact" component={ContactContainer} />
            <Route exact path="/Gallery" component={GalleryContainer} />
            <Route exact path="/Services" component={ServicesContainer} />
            <Route path="/" component={HomeContainer} />
          </Switch>
        </ScrollToTop>
        <FooterContainer />
      </div>
    );
  }
}

export default App;
