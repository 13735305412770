import { Link } from "./Interfaces";

export const Links: Link[] = [
  { display: "HOME", url: "" },
  { display: "SERVICES", url: "Services" },
  { display: "GALLERY", url: "Gallery" },
  { display: "ABOUT", url: "AboutUs" },
  { display: "CONTACT", url: "Contact" },
];

export const Email = "info@economyroofing.biz";
export const EmailDisplay = `info@\nEconomyRoofing.biz`;
export const Phone = "2157943233";
export const PhoneDisplay = "(215) 794-3233";
export const OfficePhone = "2153488686";
export const OfficePhoneDisplay = "(215) 348-8686";
export const Fax = "2157945860";
export const FaxDisplay = "(215) 794-5860";
export const Address =
  "74 Spring Lake Dr. Industrial Park, New Britain, PA 18901";
export const AddressDisplay = `74 Spring Lake Dr. Industrial Park,\nNew Britain, PA 18901`;
export const MapURL =
  "https://www.google.com/maps/d/embed?mid=1PDFK3Co-KHlqw7rKFnYutkFTbdswyPXD&hl=en";

export const AngiesList =
  "https://www.angieslist.com/companylist/us/pa/new-britain/economy-roofing-and-siding-co-inc-reviews-428478.htm";

export const Facebook =
  "https://www.facebook.com/pages/category/Roofing-Service/Economy-Roofing-and-Siding-149202748621673/";

export const YellowPages =
  "https://www.yellowpages.com/doylestown-pa/mip/economy-roofing-siding-co-462974530";
