import { Dispatch } from 'react';
import { connect } from 'react-redux';

import Operations from '../../../Redux/Operations';
import { ApplicationState, HomeContainerProps } from '../../../Utils/Interfaces';
import HomeComponent from './HomeComponent';

const mapStateToProps = (state: ApplicationState) => ({ reduxState: state });

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  const setLoaded = (loaded: boolean) => dispatch(Operations.setLoaded(loaded));
  return { setLoaded };
};

export type HomeComponentProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  HomeContainerProps;

const HomeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeComponent);

export default HomeContainer;
