import { Dispatch } from 'react';
import { connect } from 'react-redux';

import Operations from '../../../Redux/Operations';
import { AboutContainerProps, ApplicationState } from '../../../Utils/Interfaces';
import AboutComponent from './AboutComponent';

const mapStateToProps = (state: ApplicationState) => ({ reduxState: state });

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  const setLoaded = (loaded: boolean) => dispatch(Operations.setLoaded(loaded));
  return { setLoaded };
};

export type AboutComponentProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  AboutContainerProps;

const AboutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutComponent);

export default AboutContainer;
