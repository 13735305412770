import './About.scss';

import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Helmet from 'react-helmet';

import roof from '../../../Assets/Graphics/roof.png';
import Covered from '../../Common/Covered/Covered';
import { AboutComponentProps } from './AboutContainer';

class AboutComponent extends React.Component<AboutComponentProps> {
  state: {
    servicesScrolled: boolean;
  };

  constructor(props: any) {
    super(props);
    this.state = { servicesScrolled: false };
  }
  render() {
    const serviceClasses = `about-service ${
      this.state.servicesScrolled
        ? "animated fadeInRight"
        : "about-serviceHidden"
    }`;
    return (
      <div id="about">
        <Helmet>
          <title>
            Economy Roofing & Siding Inc | About Us: Building Watertight Roofs
            Since 1950
          </title>
          <link rel="canonical" href="https://economyroofingco.com/AboutUs" />
          <meta
            name="description"
            content="At Economy Roofing, we know that even a single leak is unacceptable, no matter how small. Since 1950, we’ve been building quality, watertight roofs. We got you covered with other exterior projects like siding, gutters, and windows."
          />
          <meta
            name="keywords"
            content="about overview article economy roofing inc business motto origins founded mission leaks roof watertight seal shingle gutter house covered"
          />
        </Helmet>

        <div className="about-title">About Us</div>
        <div className="about-overview">
          <ScrollAnimation
            offset={0}
            animateOnce={true}
            animateIn="fadeIn"
            className="about-services"
            afterAnimatedIn={(visible) => {
              this.setState({ servicesScrolled: true });
              return undefined;
            }}
          >
            <div className="about-servicesOverview">
              <span className="aboutServicesLarge">
                Our roofs are meticulously built.
              </span>
              <br /> <br /> Every last shingle is checked to ensure a tight
              seal.
              <br className="about-break" /> We know that even a single leak,
              <br className="about-break" /> no matter how small, is
              unacceptable.
            </div>
          </ScrollAnimation>
          <div className="about-overviewTagline">
            <span className="about-contactTaglineText animated fadeInUp delay-1s">
              {`Watertight\nSince 1950`}
              <img
                className="about-overviewRoof"
                src={roof}
                alt="Economy Roofing And Siding Roof Construction Projects Home Residential Commercial"
              />
            </span>
          </div>

          <Covered />
          <ScrollAnimation
            offset={0}
            animateOnce={true}
            animateIn="fadeIn"
            className="about-services"
          >
            <div className="about-servicesTitle">
              We offer year-round full service including:
            </div>
            <ul className="about-servicesList">
              <li className={serviceClasses}>Metal Roofing</li>
              <li className={serviceClasses}>Single Ply Rubber</li>
              <li className={serviceClasses}>Shingles</li>
              <li className={serviceClasses}>Cedar</li>
              <li className={serviceClasses}>Slate</li>
              <li className={serviceClasses}>Siding</li>
              <li className={serviceClasses}>Seamless Gutters</li>
              <li className={serviceClasses}>Spouting</li>
              <li className={serviceClasses}>Repairs</li>
            </ul>
          </ScrollAnimation>
          <ScrollAnimation
            offset={0}
            animateOnce={true}
            animateIn="fadeIn"
            className="about-specialists"
          >
            <div className="about-specialistsTitle">
              Economy Roofing & Siding
            </div>
            <div className="about-specialistsSubtitle">
              Specialists in hard to find leaks
            </div>
          </ScrollAnimation>
        </div>
      </div>
    );
  }
}

export default AboutComponent;
