import './Nav.scss';

import React from 'react';

import { NavComponentProps } from './NavContainer';
import StickyNav from './StickyNav';
import TopNav from './TopNav';

class NavComponent extends React.Component<NavComponentProps> {
  state: {
    showStickyNav: boolean;
  };

  constructor(props: NavComponentProps) {
    super(props);
    this.state = {
      showStickyNav: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", (e) => this.showStickyNav());
  }

  render() {
    return (
      <header id="nav">
        {/* <StickyNav visible={this.state.showStickyNav} /> */}
        <TopNav />
      </header>
    );
  }

  showStickyNav() {
    const scrollPos = window.pageYOffset;
    const nav = document.getElementById("top");
    if (nav)
      if (
        (nav && scrollPos > nav.clientHeight + 100) !== this.state.showStickyNav
      )
        this.setState({ showStickyNav: scrollPos > nav.clientHeight + 100 });
  }
}

export default NavComponent;
