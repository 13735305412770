import './Contact.scss';

import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Helmet from 'react-helmet';

import { AddressDisplay } from '../../../Utils/Constants';
import ContactPane from '../../Common/ContactPane/ContactPane';
import Map from '../../Common/Map/Map';
import { ContactComponentProps } from './ContactContainer';

class ContactComponent extends React.Component<ContactComponentProps> {
  render() {
    return (
      <div id="contact">
        <Helmet>
          <title>
            Economy Roofing & Siding Inc | Contact: Free Estimates, Get A Quote
            Today!
          </title>
          <link rel="canonical" href="https://economyroofingco.com/Contact" />
          <meta
            name="description"
            content="Get a quote from Economy Roofing for your upcoming roofing, siding, or other exterior projects. We do everything from residential to industrial, so call or email about any project."
          />
          <meta
            name="keywords"
            content="contact quote assessment estimate budget contact call phone number email message correspond economy roofing roofing siding projects location map address new britain pa 18901"
          />
        </Helmet>
        <div className="contact-title">Contact Us</div>
        <div className="contact-contact">
          <div className="contact-contactTitle animated fadeIn">
            Get A Quote From Economy Roofing
            <div className="contact-contactSubtitle">
              The Call In The Experts, The Leak Stops Here.
            </div>
          </div>
          <ContactPane />
        </div>
        <ScrollAnimation
          offset={0}
          animateOnce={true}
          animateIn="fadeIn"
          className="contact-mapText"
        >
          This Is Our Service Area
          <div className="contact-mapSubtext">
            Please consider us for your project if you are within the
            highlighted area
            <br />
            <br />
            We Are Located At
            <br />
            <span className="contact-address">{AddressDisplay}</span>
          </div>
        </ScrollAnimation>
        <Map />
      </div>
    );
  }
}

export default ContactComponent;
