import { Dispatch } from 'react';
import { connect } from 'react-redux';

import Operations from '../../../Redux/Operations';
import { ApplicationState, GalleryContainerProps } from '../../../Utils/Interfaces';
import GalleryComponent from './GalleryComponent';

const mapStateToProps = (state: ApplicationState) => ({ reduxState: state });

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  const setLoaded = (loaded: boolean) => dispatch(Operations.setLoaded(loaded));
  return { setLoaded };
};

export type GalleryComponentProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  GalleryContainerProps;

const GalleryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GalleryComponent);

export default GalleryContainer;
