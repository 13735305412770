import './Links.scss';
import 'react-slidedown/lib/slidedown.css';

import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { SlideDown } from 'react-slidedown';

import { Icon } from '@material-ui/core';

import Separator from '../../../Assets/Graphics/separatorBlue.png';
import { Links as WebsiteLinks } from '../../../Utils/Constants';
import { isActive, stopClickPropagation } from '../../../Utils/Functions';
import { ApplicationState, Link, LINK_TYPES, LinkProps } from '../../../Utils/Interfaces';

const shortid = require("shortid");

class Links extends React.Component<LinksComponentProps> {
  state: {
    subMenuIndex: number | undefined;
  };

  constructor(props: LinksComponentProps) {
    super(props);
    this.state = {
      subMenuIndex: undefined,
    };
  }

  componentDidUpdate(props: LinksComponentProps) {
    if (this.props.closed && this.state.subMenuIndex !== undefined)
      this.setState({ subMenuIndex: undefined });
  }

  render() {
    const links = this.getLinks(false);
    const mobileLinks = this.getLinks(true);

    return (
      <nav>
        <SlideDown
          className={`links links-mobile ${this.props.type}Mobile ${this.props.type}`}
          closed={this.props.closed}
        >
          {mobileLinks}
        </SlideDown>
        <div
          className={`links links-desktop ${this.props.type}Desktop ${this.props.type}`}
        >
          {links}
        </div>
      </nav>
    );
  }

  getLinks(mobile: boolean) {
    return WebsiteLinks.map((link: Link, idx: number) => {
      let hasSublinks = link.sublinks && link.sublinks.length > 0;
      return (
        <div className="links-container" key={shortid.generate()}>
          <div
            className={`links-linkContainer ${
              this.props.type === LINK_TYPES.STICKY
                ? "sticky-linksContainer"
                : "desktop-linksContainer"
            }`}
            key={shortid.generate()}
          >
            {this.getLink(link.display, link.url, idx, idx !== 0, hasSublinks)}
            {hasSublinks && this.props.type !== LINK_TYPES.FOOTER ? (
              mobile ? (
                <SlideDown
                  className="links-sublist"
                  closed={this.state.subMenuIndex !== idx}
                >
                  {link.sublinks!.map((sublink: Link) =>
                    this.getLink(
                      sublink.display,
                      `${link.url}/${sublink.url}`,
                      undefined
                    )
                  )}
                </SlideDown>
              ) : (
                <div className="links-sublinkContainer">
                  {link.sublinks!.map((sublink: Link) => {
                    return this.getLink(
                      sublink.display,
                      `${link.url}/${sublink.url}`,
                      undefined,
                      idx !== 0
                    );
                  })}
                </div>
              )
            ) : (
              []
            )}
          </div>
          {hasSublinks &&
            this.props.type === LINK_TYPES.FOOTER &&
            link.sublinks!.map((sublink: Link) => (
              <div className="links-linkContainer" key={shortid.generate()}>
                {this.getLink(
                  sublink.display,
                  `${link.url}/${sublink.url}`,
                  undefined,
                  idx !== WebsiteLinks.length
                )}
              </div>
            ))}
        </div>
      );
    });
  }

  getLink(
    display: string,
    url: string,
    idx: number | undefined,
    hasWrench = false,
    hasSublinks?: boolean
  ) {
    return (
      <div className="links-linkAndIcon" key={shortid.generate()}>
        {hasWrench && (
          <img
            src={Separator}
            className="links-footerSeparator"
            alt="Economy Roofing And Siding Roof Construction Projects Home Residential Commercial"
          />
        )}
        <NavLink
          exact={true}
          to={`/${url}`}
          className="links-link"
          activeClassName="links-link-active"
          isActive={isActive.bind(this, `/${url}`)}
          key={shortid.generate()}
        >
          <span className="link-text">{display}</span>
          {hasSublinks ? (
            <Icon
              className={`links-subMenuIcon ${
                this.state.subMenuIndex === idx && !this.props.closed
                  ? "links-subMenuActive"
                  : "links-subMenuInactive"
              }`}
              onClick={(e) => {
                stopClickPropagation(e);
                this.setState({
                  subMenuIndex:
                    this.state.subMenuIndex === idx ? undefined : idx,
                });
              }}
            >
              {this.state.subMenuIndex === idx ? "expand_less" : "expand_more"}
            </Icon>
          ) : null}
        </NavLink>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({ reduxState: state });

type LinksComponentProps = ReturnType<typeof mapStateToProps> & LinkProps;

const LinksContainer = connect(mapStateToProps)(Links);

export default LinksContainer;
