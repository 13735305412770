import { Dispatch } from 'react';
import { connect } from 'react-redux';

import Operations from '../../../Redux/Operations';
import { ApplicationState, ContactContainerProps } from '../../../Utils/Interfaces';
import ContactComponent from './ContactComponent';

const mapStateToProps = (state: ApplicationState) => ({ reduxState: state });

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  const setLoaded = (loaded: boolean) => dispatch(Operations.setLoaded(loaded));
  return { setLoaded };
};

export type ContactComponentProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ContactContainerProps;

const ContactContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactComponent);

export default ContactContainer;
