import './MenuIcon.scss';

import React from 'react';

import hammerLeft from '../../../Assets/Graphics/hammerLeft.png';
import hammerRight from '../../../Assets/Graphics/hammerRight.png';
import bottom from '../../../Assets/Graphics/nailBottom.png';
import middle from '../../../Assets/Graphics/nailMiddle.png';
import top from '../../../Assets/Graphics/nailTop.png';
import { MenuIconProps } from '../../../Utils/Interfaces';

class MenuIcon extends React.Component<MenuIconProps> {
  render() {
    return (
      <div
        className={`menu ${this.props.closed ? "menu-closed" : "menu-open"}`}
      >
        <img
          src={top}
          className="menu-icon menu-screwTop "
          alt="Economy Roofing And Siding Roof Construction Projects Home Residential Commercial"
        />
        <img
          src={middle}
          className="menu-icon menu-screwMiddle"
          alt="Economy Roofing And Siding Roof Construction Projects Home Residential Commercial"
        />
        <img
          src={hammerRight}
          className="menu-icon menu-hammer menu-hammerRight"
          alt="Economy Roofing And Siding Roof Construction Projects Home Residential Commercial"
        />
        <img
          src={hammerLeft}
          className="menu-icon menu-hammer menu-hammerLeft"
          alt="Economy Roofing And Siding Roof Construction Projects Home Residential Commercial"
        />
        <img
          src={bottom}
          className="menu-icon menu-screwBottom"
          alt="Economy Roofing And Siding Roof Construction Projects Home Residential Commercial"
        />
      </div>
    );
  }
}

export default MenuIcon;
