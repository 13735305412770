import './Services.scss';

import React from 'react';
import ScrollAnimation, { VisibleType } from 'react-animate-on-scroll';
import Helmet from 'react-helmet';

import ContactPane from '../../Common/ContactPane/ContactPane';
import { ServicesComponentProps } from './ServicesContainer';

class ServicesComponent extends React.Component<ServicesComponentProps> {
  state: {
    servicesAnimated: boolean;
    brandsAnimated: boolean;
  };

  constructor(props: any) {
    super(props);
    this.state = {
      servicesAnimated: false,
      brandsAnimated: false
    };
  }

  render() {
    const serviceClassList = `services-service `;
    const serviceTitleClassList = `services-serviceTitleContainer ${
      this.state.servicesAnimated ? "animated fadeInRight" : ""
    }`;
    const serviceListClassList = `services-item ${
      this.state.servicesAnimated ? "animated fadeInUp" : ""
    }`;
    const brandsClassList = `services-brandsContainer ${
      this.state.brandsAnimated ? "animated fadeIn" : ""
    }`;
    const brandsBrandClassList = `services-brandsListItem ${
      this.state.brandsAnimated ? "animated fadeInRight" : ""
    }`;

    return (
      <div id="services">
        <Helmet>
          <title>
            Economy Roofing & Siding Inc | Our Services: Roofing, Siding,
            Windows, Gutters & Exterior Construction Projects
          </title>
          <link rel="canonical" href="https://economyroofingco.com/Services" />
          <meta
            name="description"
            content="Economy Roofing offers exterior builds of the foremost quality. We are experts in construction of roofs for residential, commercial, and industrial buildings, siding, gutters, and windows. Every project uses materials from quality brands and suppliers. We can provide a quote or estimate for your project."
          />
          <meta
            name="keywords"
            content="services contracting roofing shingles hardware windows gutters downspouts repairs leaks siding wood aluminum vinyl slate cedar asphalt premium quality brands elevate andersen atlas gaf james hardie quote"
          />
        </Helmet>

        <div className="services-title">Our Services</div>
        <ScrollAnimation
          offset={50}
          animateIn="fadeIn"
          animateOnce={true}
          afterAnimatedIn={(visible) => {
            this.setState({ servicesAnimated: true });
            return undefined;
          }}
          className="services-services"
          duration={0}
          delay={300}
          style={{ opacity: this.state.servicesAnimated ? 1 : 0 }}
        >
          <div className={serviceClassList}>
            <div className={serviceTitleClassList}>
              <div className="services-serviceTitle">Roofing</div>
            </div>
            <ul className="services-serviceItemsList">
              <li className={serviceListClassList}>
                Shingles:
                <div className="services-itemSublistContainer">
                  <span className="services-itemSublist">Cedar</span>
                  <span className="services-itemSublist">Slate</span>
                  <span className="services-itemSublist">Asphalt</span>
                  <span className="services-itemSublist">Fiberglass</span>
                </div>
              </li>
              <li className={serviceListClassList}>Rubber</li>
              <li className={serviceListClassList}>Metal</li>
            </ul>
          </div>
          <div className={serviceClassList}>
            <div className={serviceTitleClassList}>
              <div className="services-serviceTitle">Hardware</div>
            </div>
            <ul className="services-serviceItemsList">
              <li className={serviceListClassList}>
                In-House
                <br />
                Sheet Metal Shop
              </li>
              <li className={serviceListClassList}>Windows</li>
              <li className={serviceListClassList}>Seamless Gutters</li>
              <li className={serviceListClassList}>Downspouts & Spouting</li>
            </ul>
          </div>
          <div className={serviceClassList}>
            <div className={serviceTitleClassList}>
              <div className="services-serviceTitle">Repairs</div>
            </div>
            <ul className="services-serviceItemsList">
              <li className={serviceListClassList}>Leaks</li>
              <li className={serviceListClassList}>Restoration</li>
              <li className={serviceListClassList}>Free Estimates</li>
              <li className={serviceListClassList}>Year-Round Service</li>
            </ul>
          </div>
          <div className={serviceClassList}>
            <div className={serviceTitleClassList}>
              <div className="services-serviceTitle">Siding</div>
            </div>
            <ul className="services-serviceItemsList">
              <li className={serviceListClassList}>Wood</li>
              <li className={serviceListClassList}>Vinyl</li>
              <li className={serviceListClassList}>Aluminum</li>
              <li className={serviceListClassList}>Cement Siding</li>
            </ul>
          </div>
          <div
            className={`services-border services-borderHorizontal ${
              this.state.servicesAnimated ? "animated zoomIn" : ""
            }`}
          />
          <div
            className={`services-border services-borderVertical ${
              this.state.servicesAnimated ? "animated zoomIn" : ""
            }`}
          />
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="fadeIn"
          offset={50}
          animateOnce={true}
          duration={0.1}
          delay={300}
          afterAnimatedIn={(visible) => {
            this.setState({ brandsAnimated: true });
            return undefined;
          }}
          className={brandsClassList}
        >
          <div
            className={`services-brandsTitle ${
              this.state.brandsAnimated ? "animated fadeIn" : ""
            }`}
            style={{
              visibility: this.state.brandsAnimated ? "visible" : "hidden"
            }}
          >
            We Use The Following Premium Brands
          </div>
          <ul
            className="services-brandsList"
            style={{
              visibility: this.state.brandsAnimated ? "visible" : "hidden"
            }}
          >
            <li className={brandsBrandClassList}>Alcoa</li>
            <li className={brandsBrandClassList}>Andersen</li>
            <li className={brandsBrandClassList}>Atlas</li>
            <li className={brandsBrandClassList}>Carlisle</li>
            <li className={brandsBrandClassList}>CertainTeed</li>
            <li className={brandsBrandClassList}>Commercial</li>
            <li className={brandsBrandClassList}>Drexel Metal</li>
            <li className={brandsBrandClassList}>Elevate</li>
            <li className={brandsBrandClassList}>GAF</li>
            <li className={brandsBrandClassList}>JamesHardie</li>
            <li className={brandsBrandClassList}>Residential</li>
          </ul>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="fadeIn"
          offset={50}
          animateOnce={true}
          delay={200}
          className="services-contactContainer"
        >
          <div className="services-contactTitle">
            Call Or Email Today For A Free Quote
          </div>
          <ContactPane />
        </ScrollAnimation>
      </div>
    );
  }
}

export default ServicesComponent;
