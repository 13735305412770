import './Home.scss';

import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Helmet from 'react-helmet';
import { NavLink } from 'react-router-dom';

import logo from '../../../Assets/Graphics/logoShadow.png';
import roof from '../../../Assets/Graphics/roof.png';
import separator from '../../../Assets/Graphics/separatorBlue.png';
import ContactPane from '../../Common/ContactPane/ContactPane';
import Map from '../../Common/Map/Map';
import { HomeComponentProps } from './HomeContainer';

class HomeComponent extends React.Component<HomeComponentProps> {
  state: {
    talkLoaded: boolean;
  };

  constructor(props: any) {
    super(props);
    this.state = { talkLoaded: false };
  }

  render() {
    return (
      <div id="home">
        <Helmet>
          <title>
            Economy Roofing & Siding Inc | A Professional Roofing & Siding
            Contractor Serving Bucks & Montgomery County Area r
          </title>
          <link rel="canonical" href="https://economyroofingco.com/" />
          <meta
            name="description"
            content="Economy Roofing’s residential, commercial, & industrial projects guaranteed against leaks. We have knowledge and experience in most exterior builds and repairs: roofing, siding, windows, gutters, and more."
          />
          <meta
            name="keywords"
            content="roofing siding windows gutters homepage contractor constructions residential commercial industrial leak leaky company summary business builder roofer exterior"
          />
        </Helmet>

        <div className="home-header animated fadeIn fast">
          <div className="home-headerImageContainer animated fadeInLeft delay-1s">
            <div className="home-headerImage" />
            <div className="home-headerTaglineMobile">
              <span className="home-taglineItalics">Specialists</span> in hard
              to find leaks
            </div>
          </div>
          <div className="home-headerInfo animated fadeInRight delay-1s">
            <div className="home-headerInfoContent">
              <img
                src={logo}
                className="home-headerLogo"
                alt="Economy Roofing And Siding Roof Construction Projects Home Residential Commercial"
              />
              <div className="home-headerTenants">
                <span className="home-headerTenant">Residential</span>
                <img
                  src={separator}
                  className="home-headerTenantSeparator"
                  alt="Economy Roofing And Siding Roof Construction Projects Home Residential Commercial"
                />
                <span className="home-headerTenant">Industrial</span>
                <img
                  src={separator}
                  className="home-headerTenantSeparator"
                  alt="Economy Roofing And Siding Roof Construction Projects Home Residential Commercial"
                />
                <span className="home-headerTenant">Commercial</span>
              </div>
              <div className="home-headerTagline">
                <span className="home-taglineItalics">Specialists</span> in hard
                to find leaks
              </div>
              <NavLink to="/Contact" className="home-headerButton">
                <div>
                  <span className="home-headerButtonBold">Contact Us</span>
                  <br />
                  <span className="home-headerButtonItalic">For A</span>
                  <br />
                  <span className="home-headerButtonBold">Free Estimate!</span>
                </div>
              </NavLink>
            </div>
          </div>
        </div>

        <ScrollAnimation
          offset={0}
          animateIn="fadeIn"
          animateOnce={true}
          className="home-intro"
        >
          <div className="home-introTagline">
            Attention To Detail
            <div className="home-introText">
              <br />
              <span className="home-break" />
              It's what makes Economy Roofing & Siding the superior choice
              <br />
              for residential and commercial projects.
              <span className="home-break" />
              <br />
              <br />
              From a small house to large commercial properties,
              <br /> we pay attention and do the job right.
            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation
          offset={0}
          duration={0}
          animateIn="fadeIn"
          animateOnce={true}
          className="home-contact"
          afterAnimatedIn={(visible) => {
            this.setState({ talkLoaded: true });
            return undefined;
          }}
        >
          <div className="home-contactTitle">
            <span
              className={`home-contactTitleText ${
                this.state.talkLoaded
                  ? "animated fadeInUp"
                  : "home-contactTitleTextHidden"
              }`}
            >
              <img
                className="home-contactRoof"
                src={roof}
                alt="Economy Roofing And Siding Roof Construction Projects Home Residential Commercial"
              />
              {`Let's Talk\nAbout Roofing`}
            </span>
          </div>
        </ScrollAnimation>
        <ContactPane />
        <ScrollAnimation animateIn="fadeIn" className="home-mapText">
          This Is Our Service Area
          <div className="home-mapSubtext">
            Please Contact Us If You Are Close By
          </div>
        </ScrollAnimation>
        <Map />
      </div>
    );
  }
}

export default HomeComponent;
