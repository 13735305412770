import './Gallery.scss';

import React from 'react';
import Helmet from 'react-helmet';
import ReactImageGallery from 'react-image-gallery';

import Images from '../../../Utils/Images';
import { GalleryComponentProps } from './GalleryContainer';

class GalleryComponent extends React.Component<GalleryComponentProps> {
  render() {
    return (
      <div id="gallery">
        <Helmet>
          <title>
            Economy Roofing & Siding Inc | Gallery: The Best Roofs A Contractor
            Can Offer
          </title>
          <link rel="canonical" href="https://economyroofingco.com/Gallery" />
          <meta
            name="description"
            content="View our gallery of the finest roofing PA has to offer. We have a portfolio spanning from a traditional slate roof on a historic barn, up to large complexes and car dealerships. We have no problem with challenging, unconventional designs... see for yourself."
          />
          <meta
            name="keywords"
            content="Gallery slideshow media pictures images photos photographs buildings church car dealership mall apartment complex commercial construction contracting building content photography"
          />
        </Helmet>

        <div className="gallery-title">View Our Work</div>
        <div className="gallery-container animated fadeIn">
          <ReactImageGallery items={Images} />
        </div>
      </div>
    );
  }
}

export default GalleryComponent;
