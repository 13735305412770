import './Bam.scss';

import React from 'react';

import bam from '../../../Assets/Graphics/bam.png';
import Separator from '../Separator/Separator';

class Bam extends React.Component {
  render() {
    return (
      <div className="bam">
        <a
          href="https://bamdigitalco.com"
          target="blank"
          rel="noreferrer noopener"
          className="bam-container"
        >
          <img
            src={bam}
            alt="bam websites logos marketing"
            className="bam-logo"
          />
        </a>
      </div>
    );
  }
}

export default Bam;
