import './Footer.scss';

import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import logo from '../../../Assets/Graphics/logo.png';
import { ApplicationState, LINK_TYPES } from '../../../Utils/Interfaces';
import Bam from '../Bam/Bam';
import LinksContainer from '../Nav/Links';

class Footer extends React.Component<FooterComponentProps> {
  render() {
    const date = new Date();
    const year = date.getFullYear();
    return (
      <div className="footer">
        <NavLink exact={true} to="/" className="footer-logoLink">
          <img
            src={logo}
            className="footer-logo"
            alt="Economy Roofing And Siding Roof Construction Projects Home Residential Commercial"
          />
        </NavLink>
        <LinksContainer type={LINK_TYPES.FOOTER} closed={false} />

        <div className="footer-copy">
          &copy; {year} Economy Roofing & Siding Company Inc.
        </div>
        <Bam />
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  reduxState: state,
});

type FooterComponentProps = ReturnType<typeof mapStateToProps>;

const FooterContainer = connect(mapStateToProps)(Footer);

export default FooterContainer;
