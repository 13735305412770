import { connect } from 'react-redux';

import { ApplicationState } from '../../../Utils/Interfaces';
import NavComponent from './NavComponent';

const mapStateToProps = (state: ApplicationState) => ({ reduxState: state });

export type NavComponentProps = ReturnType<typeof mapStateToProps>;

const NavContainer = connect(mapStateToProps)(NavComponent);

export default NavContainer;
