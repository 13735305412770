import './Covered.scss';

import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import emblem from '../../../Assets/Graphics/Covered/emblemCircle.png';
import factory from '../../../Assets/Graphics/Covered/factory.png';
import house from '../../../Assets/Graphics/Covered/house.png';
import triangle from '../../../Assets/Graphics/Covered/triangle.png';

class Covered extends React.Component {
  state: {
    loaded: boolean;
  };

  constructor(props: any) {
    super(props);
    this.state = { loaded: false };
  }
  render() {
    return (
      <ScrollAnimation
        offset={0}
        animateOnce={true}
        animateIn={"fadeIn"}
        className="covered"
        delay={300}
        afterAnimatedIn={(visible) => {
          this.setState({ loaded: true });
          return undefined;
        }}
      >
        {this.state.loaded ? (
          this.animation()
        ) : (
          <div style={{ opacity: 0 }}> {this.animation()}</div>
        )}
      </ScrollAnimation>
    );
  }

  animation() {
    return (
      <>
        <div className="covered-titleTop animated fadeIn">
          We've Got You Covered
        </div>
        <div className="covered-animation">
          <div className="covered-imageContainer covered-imageContainerLeft animated zoomIn fast ">
            <img
              src={house}
              className="covered-image covered-imageLeft"
              alt="Economy Roofing And Siding Roof Construction Projects Home Residential Commercial Industrial"
            />
            {`Residential\nProjects`}
          </div>
          <div className="covered-middleContainer">
            <img
              src={triangle}
              className="covered-image covered-imageMiddle"
              alt="Economy Roofing And Siding Roof Construction Projects Home Residential Commercial"
            />
            <img
              src={emblem}
              className="covered-emblem animated rotateIn fast"
              alt="Economy Roofing And Siding Roof Construction Projects Home Residential Commercial"
            />
          </div>
          <div className="covered-imageContainer covered-imageContainerRight animated zoomIn fast">
            <img
              src={factory}
              className="covered-image covered-imageRight"
              alt="Economy Roofing And Siding Roof Construction Projects Home Residential Commercial"
            />
            {`Industrial\nCommercial`}
          </div>
        </div>
        <div className="covered-titleBottom animated fadeIn">
          Any Size Project
        </div>
      </>
    );
  }
}

export default Covered;
