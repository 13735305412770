import { ReactImageGalleryItem } from 'react-image-gallery';

import Roof1 from '../Assets/Images/Roofing/1.jpg';
import Roof10 from '../Assets/Images/Roofing/10.jpg';
import Roof12 from '../Assets/Images/Roofing/12.jpg';
import Roof13 from '../Assets/Images/Roofing/13.jpg';
import Roof15 from '../Assets/Images/Roofing/15.jpg';
import Roof16 from '../Assets/Images/Roofing/16.jpg';
import Roof18 from '../Assets/Images/Roofing/18.jpg';
import Roof19 from '../Assets/Images/Roofing/19.jpg';
import Roof20 from '../Assets/Images/Roofing/20.jpg';
import Roof21 from '../Assets/Images/Roofing/21.jpg';
import Roof22 from '../Assets/Images/Roofing/22.jpg';
import Roof23 from '../Assets/Images/Roofing/23.jpg';
import Roof24 from '../Assets/Images/Roofing/24.jpg';
import Roof25 from '../Assets/Images/Roofing/25.jpg';
import Roof3 from '../Assets/Images/Roofing/3.jpg';
import Roof4 from '../Assets/Images/Roofing/4.jpg';
import Roof5 from '../Assets/Images/Roofing/5.jpg';
import Roof6 from '../Assets/Images/Roofing/6.jpg';
import Roof7 from '../Assets/Images/Roofing/7.jpg';
import Roof8 from '../Assets/Images/Roofing/8.jpg';

const Images: ReactImageGalleryItem[] = [
  {
    original: Roof1,
    originalAlt:
      "Stunning slate roofing with bold, sharp edges on this red brick on this colonel estate",
    thumbnail: Roof1,
    thumbnailAlt:
      "Stunning slate roofing with bold, sharp edges on this red brick on this colonel estate thumbnail",
  },
  {
    original: Roof3,
    originalAlt:
      "Traditional slate roofing with snow guards and wood siding on this colonel barn",
    thumbnail: Roof3,
    thumbnailAlt:
      "Traditional slate roofing with snow guards and wood siding on this colonel barn thumbnail",
  },
  {
    original: Roof4,
    originalAlt: "Metal roofing with snow guards over an outdoor fireplace",
    thumbnail: Roof4,
    thumbnailAlt:
      "Metal roofing with snow guards over an outdoor fireplace thumbnail",
  },
  {
    original: Roof5,
    originalAlt:
      "A commercial roofing project with complex peaks and Gablet roof slopes",
    thumbnail: Roof5,
    thumbnailAlt:
      "A commercial roofing project with complex peaks and Gablet roof slopes thumbnail",
  },
  {
    original: Roof6,
    originalAlt:
      "Flaired Mansard roof in traditional slate, Located in Chalfont, it's a true demonstration of our craftsmanship",
    thumbnail: Roof6,
    thumbnailAlt:
      "Flaired Mansard roof in traditional slate, Located in Chalfont, it's a true demonstration of our craftsmanship thumbnail",
  },
  {
    original: Roof7,
    originalAlt:
      "Industrial & commercial roofing both featured on this large flat-roof project",
    thumbnail: Roof7,
    thumbnailAlt:
      "Industrial & commercial roofing both featured on this large flat-roof project thumbnail",
  },
  {
    original: Roof8,
    originalAlt:
      "Bold architecture displayed in this sharp metal roof for the John and Jacqueline E. Swartz Education Center",
    thumbnail: Roof8,
    thumbnailAlt:
      "Bold architecture displayed in this sharp metal roof for the John and Jacqueline E. Swartz Education Center thumbnail",
  },
  {
    original: Roof10,
    originalAlt:
      "A precisely angled shingled roof on a historic stone building",
    thumbnail: Roof10,
    thumbnailAlt:
      "A precisely angled shingled roof on a historic stone building thumbnail",
  },
  {
    original: Roof12,
    originalAlt:
      "Multiple shingle styles shown off in this construction progress photo",
    thumbnail: Roof12,
    thumbnailAlt:
      "Multiple shingle styles shown off in this construction progress photo thumbnail",
  },
  {
    original: Roof13,
    originalAlt: "Waterproofing and preparation for a strong new roof",
    thumbnail: Roof13,
    thumbnailAlt:
      "Waterproofing and preparation for a strong new roof thumbnail",
  },
  {
    original: Roof15,
    originalAlt: "A flat roofing project in progress",
    thumbnail: Roof15,
    thumbnailAlt: "A flat roofing project in progress thumbnail",
  },
  {
    original: Roof16,
    originalAlt: "We are capable of large roofing projects like this",
    thumbnail: Roof16,
    thumbnailAlt:
      "We are capable of large roofing projects like this thumbnail",
  },
  {
    original: Roof18,
    originalAlt:
      "A view of our work on this large residential apartment building",
    thumbnail: Roof18,
    thumbnailAlt:
      "A view of our work on this large residential apartment building thumbnail",
  },
  {
    original: Roof19,
    originalAlt:
      "Roofing for luxury residential homes with the highest quality shingle work",
    thumbnail: Roof19,
    thumbnailAlt:
      "Roofing for luxury residential homes with the highest quality shingle work thumbnail",
  },
  {
    original: Roof20,
    originalAlt:
      "The skill and precision for the roofing and siding on a dream estate",
    thumbnail: Roof20,
    thumbnailAlt:
      "The skill and precision for the roofing and siding on a dream estate thumbnail",
  },
  {
    original: Roof21,
    originalAlt:
      "Mansion construction progress with roofing and siding by Economy Roofing",
    thumbnail: Roof21,
    thumbnailAlt:
      "Mansion construction progress with roofing and siding by Economy Roofing thumbnail",
  },
  {
    original: Roof22,
    originalAlt:
      "Economy Roofing Inc. Company construction trucks and vehicles",
    thumbnail: Roof22,
    thumbnailAlt:
      "Economy Roofing Inc. Company construction trucks and vehicles thumbnail",
  },
  {
    original: Roof23,
    originalAlt: "Curved metal roof in striking red for Citadel",
    thumbnail: Roof23,
    thumbnailAlt: "Curved metal roof in striking red for Citadel thumbnail",
  },
  {
    original: Roof24,
    originalAlt:
      "Large church terracotta shingle roofing project in buckingham Doylestown",
    thumbnail: Roof24,
    thumbnailAlt:
      "Large church terracotta shingle roofing project in buckingham Doylestown thumbnail",
  },
  {
    original: Roof25,
    originalAlt: "In-progress gently sloped roof with windows skylights",
    thumbnail: Roof25,
    thumbnailAlt:
      "In-progress gently sloped roof with windows skylights thumbnail",
  },
];

export default Images;
