export function isActive(path: any, match: any, location: any) {
  return !!(
    match ||
    location.pathname === path ||
    (location.pathname.length === path.length &&
      location.pathname.search(path) !== -1)
  );
}

export function stopClickPropagation(e: React.MouseEvent) {
  e.preventDefault();
  e.stopPropagation();
  e.nativeEvent.stopPropagation();
}

export function makeLink(original: string) {
  return encodeURIComponent(
    original
      .split(" ")
      .join("")
      .replace(/[^a-zA-Z ]/g, "")
  );
}
